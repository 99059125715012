import React from "react";
import "../assets/css/Footer.css";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { ED } from "../components/EpicDev";
import { Social } from "../components/Social";
import CG from "../assets/Images/CityGuild.png";
import GS from "../assets/Images/GasSafe.png";

export const FooterBar = () => {
  const data = useStaticQuery(graphql`
    query {
      gasSafe: file(relativePath: { eq: "GasSafe.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 75, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cityGuild: file(relativePath: { eq: "CityGuild.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 120, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      oftec: file(relativePath: { eq: "oftec.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 80, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <div className="footer">
      <div className="footer-contents">
        <Social />
        <div className="copywrite">
          &copy; BBIS Heating 2025
          <br />
          <Link to="/privacy-policy/" className="footer-link">
            Privacy Policy
          </Link>
        </div>
      </div>
      <div className="business-icons">
        <div style={{ display: "flex" }}>
          <Img
            fixed={data.gasSafe.childImageSharp.fixed}
            alt="Gas Safe Logo"
          />
          <Img
            fixed={data.cityGuild.childImageSharp.fixed}
            alt="City and Guilds Logo"
          />
         <Img
            fixed={data.oftec.childImageSharp.fixed}
            alt="oftec logo"
          />
        </div>
        {/* <div style={{ display: "flex" }}>
          <Img
            fluid={data.gasSafe.childImageSharp.fluid}
            style={{ width: "99px" }}
          />
          <Img
            fluid={data.cityGuild.childImageSharp.fluid}
            style={{ width: "154px" }}
          />
        </div> */}
      </div>
    </div>
  );
};
